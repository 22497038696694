import { io } from "socket.io-client";
import type { Socket } from "socket.io-client";
import type { API as API_CORE, API_DECLARATION, DeepKeys, ParametersAPI, ValuesAPI } from "../../../API";


type AdvancedCallOptions = {
  maxTime?: number;
  partial?: (...content: any[]) => any
}

function getRandomSymbol (symbol) {
    let array;

    if (symbol === 'y') {
        array = ['8', '9', 'a', 'b'];
        return array[Math.floor(Math.random() * array.length)];
    }

    array = new Uint8Array(1);
    window.crypto.getRandomValues(array);
    return (array[0] % 16).toString(16);
}

export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, getRandomSymbol);
}

export class ApiMessage {
  message: unknown;
  respond: () => void;

  constructor(message: unknown, respond: () => void) {
      this.message = message;
      this.respond = respond;
  }

}

export type CallbackList = ((...data: any[]) => void)[];

export class Api {

    callback: { [key: string]: CallbackList } = {};
    onConnectedCallback: CallbackList = [];
    connected: boolean = false;

    constructor() {
    }

    on(head: string, callback: (...data: any[]) => any) {
        if (!this.callback[head]) this.callback[head] = [];
        this.callback[head].push(callback);
    }

    onConnected(callback: () => any){
        this.onConnectedCallback.push(callback);
        if (this.connected) callback();
    }

    remove(head: DeepKeys<typeof API_CORE>, call: () => void) {
        if (this.callback[head]) {
            const index = this.callback[head].indexOf(call);
            if (index >= 0) this.callback[head].splice(index, 1);
        }
    }

    clear(head: string){
        if (this.callback[head]) this.callback[head].length = 0;
    }

    emit(head, ...data: any[]){

    }
  
  //ParamsApiDeep<H, API_TYPE>
  //@ts-ignore
    call<K extends DeepKeys<typeof API_CORE>, P extends ParametersAPI<K, typeof API_CORE>>(head: K, ...params: P): Promise<ValuesAPI<K, typeof API_CORE>> {
        return this.callAdvanced(head, {
            maxTime: 10000,
        }, ...params);
    }

    callPartial<K extends DeepKeys<typeof API_CORE>, P extends ParametersAPI<K, typeof API_CORE>>(head: K, partial: (...content: any[]) => any, ...params: P): Promise<ValuesAPI<K, typeof API_CORE>> {
        return this.callAdvanced(head, {
            maxTime: undefined,
            partial,
        }, ...params);
    }

    callAdvanced<K extends DeepKeys<typeof API_CORE>, P extends ParametersAPI<K, typeof API_CORE>>(head: K, options: AdvancedCallOptions, ...params: P): Promise<ValuesAPI<K, typeof API_CORE>> {
        return new Promise((res, rej) => {
            const responseID = uuidv4();
            // console.log(head, params);
            for(let i = 0; i < params.length; i++){
                if (typeof params[i] == 'object'){
                    try{
                        ((window as any).structuredClone)(params[i])
                    }catch {
                        params[i] = JSON.parse(JSON.stringify(params[i]));
                    }
                }
            }
            //   console.log(head, params);
            this.emit(head, { responseID }, ...params as any);
            let timeout: number = 0;
            const cutListener = () => {
                clearTimeout(timeout);
                API.remove(`${responseID}@success` as any, success);
                API.remove(`${responseID}@error` as any, success);
                API.remove(`${responseID}@partial` as any, success);
            }
            const success = (...data: any[]) => {
                (res as any)(...data);
                cutListener();
            };
            const error = (data: any) => {
                console.log("ERROR", data);
                rej(data);
                cutListener();
            };
            const partialCall = (data: any) => {
                options?.partial?.(data);
            }

            API.on(`${responseID}@success` as any, success);
            API.on(`${responseID}@error` as any, error);
            if (options?.partial) API.on(`${responseID}@partial`, partialCall);

            if(options.maxTime != undefined){
                timeout = setTimeout(() => {
                    error(`${head} : Timeout, the server doesn't respond`);
                }, options.maxTime) as any;
            }
        });
    } 

  /** Emit evenement **/
    receive(head: DeepKeys<typeof API_CORE>, ...params: any[]) {
        if (this.callback[head]) {
            for (let i = 0; i < this.callback[head].length; i++) {
                this.callback[head][i](...params);
            }
        }
    }

    async callOnActive(){
        this.connected = true;
        const promises: any[] = [];
        for (let i = 0; i < this.onConnectedCallback.length; i++){
            promises.push(this.onConnectedCallback[i]());
        }
        await Promise.all(promises);
    }

    async initialize() {
        await this.callOnActive();
    }

    stop() {

    }

}
/**
* System to communicate with Backend
* 
* *Wait for a event emitted from back*
* ```js
* API.on("ping", () => {
*      console.log("ping emitted from back");
* })
* ```
* 
* *Send api call to backend*
* ```js
* const result = await API.call("example.ping");
* ```
*/
export const API = new Api();
//Make API Global
(window as any).API = API;
let socket: Socket;

API.stop = function () {
  console.debug("[API] End of communication");
  if (socket) socket.close();
  API.stop = () => { };
  API.initialize = async function() {
      await this.callOnActive();
  };
}

API.initialize = function () {
  return new Promise((res, rej) => {
      socket = io((window as any).DEV_MODE != "production" ? '' : '', { transports: ["websocket"] });
  
      API.emit = (head, ...params) => {
          socket.emit(head, ...params);
      }
  
      socket.onAny((eventName, ...params) => {
          API.receive(eventName, ...params);
      })
  
      socket.on("connect", async () => {
          console.debug("[API] Connection established");
          await this.callOnActive();
          res();
      })
  })
}